<template>
  <div id="community" class="full-size">
    <h1>Community</h1>
    <p class="sub-claim">
      A huge undertaking will only be successful with the help of many people and sufficient funding.
      While money can be found relatively easy the right people are harder to find. We are looking
      for people who have the right mindset, are willing to learn new things or share their knowledge from previous
      experiences. You can participate in the form of a working vacation as a volunteer or become
      a temporary employee for a larger project.
    </p>
    <p class="sub-claim">
      Our land is also open to teachers and intructors from the communities of permaculture and
      regenerative agriculture to host workshops and courses.
    </p>
    <div v-if="hasCommunity" class="two-sided" style="justify-content: space-between;">
      <p></p>
      <a href="/community" class="button">Learn more about the community</a>
    </div>
    <p v-else class="sub-claim">
      The community section is currently under construction. Please check back later for more details.
    </p>
  </div>
</template>


<script>
import { setupFeatureFlags } from '@/library'

export default {
  name: "MainCommunity",
  computed: {
    runtimeProfile () {
      return setupFeatureFlags()
    },
    hasCommunity () {
      return this.runtimeProfile.hasCommunity
    },
  },
}
</script>
