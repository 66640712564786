<template>
  <div id="agriculture" class="full-size">
    <h1>Agriculture</h1>
    <div class="two-sided">
      <div>
        <h2>Seek Abundance and Take Away the Surplus</h2>
        <p>
          Nature thrives on abundance and because of that natural systems are more resilient. We operate in the
          mediterranean climate with a prolonged summer drought with no rain for about 6 months. We only take away the
          surplus so that e.g. our soil is always protected from the harsh sun.
        </p>
        <figure>
          <img src="@/assets/images/landing/2022-04-08_18-54-20.jpg" />
          <figcaption>An abundance of grasses and other plants</figcaption>
        </figure>
        <figure>
          <img src="@/assets/images/landing/2021-03-31_13-34-30.jpg" />
          <figcaption>Truckloads of biomass during summer</figcaption>
        </figure>
      </div>
      <div>
        <h2>Specialty Food</h2>
        <p>
          As our soil improves and becomes more and more fertile, we can harvest the surplus. Two examples of our
          specialty food products:
        </p>
        <figure>
          <img src="@/assets/images/landing/2022-11-11_11-45-16.jpg" />
          <figcaption>Ham from acorn-fed Iberian pigs</figcaption>
        </figure>
        <figure>
          <img src="@/assets/images/landing/2022-12-03_13-35-09.jpg" />
          <figcaption>Spicy chili grown in the hot Andalusian sun</figcaption>
        </figure>
      </div>
    </div>
    <div class="two-sided" style="justify-content: space-between;">
      <p></p>
      <a href="/concept/overview" class="button right">Learn more about our concept</a>
    </div>
  </div>
</template>
