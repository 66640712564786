<template>
  <div class="container">
    <MainAgriculture />
    <MainCommunity />
    <MainInvestment />
  </div>
</template>

<script>
import MainAgriculture from './MainAgriculture.vue';
import MainInvestment from './MainInvestment.vue';
import MainCommunity from './MainCommunity.vue';

export default {
  name: "HomeIndexEN",
  components: { MainAgriculture, MainInvestment, MainCommunity },
};
</script>
