<template>
  <div id="investment" class="full-size">
    <h1>Investment</h1>
    <div v-if="hasInvestment" class="two-sided" style="justify-content: space-between;">
      <p></p>
      <a href="/investment" class="button">Learn more about investment opportunities</a>
    </div>
    <div v-else>
      <p class="sub-claim">
        What we aim to achieve is a huge undertaking. You can support us and earn some money in the
        process by participating in our investment program. More details will be published soon.</p>
    </div>
  </div>
</template>


<script>
import { setupFeatureFlags } from '@/library'

export default {
  name: "MainInvestment",
  computed: {
    runtimeProfile () {
      return setupFeatureFlags()
    },
    hasInvestment () {
      return this.runtimeProfile.hasInvestment
    },
  },
}
</script>
